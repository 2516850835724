import css from 'styled-jsx/css';

export default css`
  .footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1em 0em;
    flex-direction: column;
    grid-area: footer;
    background: var(--background-home-2);
  }
  .footer__menu {
    display: flex;
    max-width: 1480px;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    border-bottom: none;
    padding: 1em 1em;
  }
  .footer__info,
  .footer__social {
    display: flex;
    max-width: 1280px;
    width: 100%;
    justify-content: center;
    padding: 1em;
  }
  .footer__terms {
    display: flex;
    max-width: 1280px;
    width: 100%;
    justify-content: center;
    padding: 1em 1em;
  }
  .footer__social,
  .footer__terms {
    gap: 20px;
  }
  .footer__social :global(.icon) {
    font-size: 22px !important;
  }
  .footer__menu-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 0;
    gap: 16px;
  }
  .footer__menu-box p {
    cursor: pointer;
  }
  .footer__menu-box p:hover {
    text-decoration: underline;
  }
  .footer__menu-box h6 {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  .footer__menu-box p {
    font-size: 15px;
    font-weight: 400;
  }
  .footer__terms a {
    font-size: 15px;
    text-decoration: underline;
  }
  .footer :global(.accordion-container) {
    border-bottom: var(--line);
    padding: 16px;
    margin-top: 0;
  }
  .footer :global(.accordion-text) {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    text-align: left;
    color: var(--foreground);
  }
  .footer :global(.accordion-icon) {
    font-size: 22px;
    font-weight: 600;
    right: 16px;
    color: var(--foreground);
  }
  .footer__accordion {
    gap: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .payment img {
    max-width: 40px;
  }
  @media only screen and (max-width: 600px) {
    .footer__menu {
      padding: 1em 0 1em !important;
    }
    .footer__info,
    .footer__social {
      display: flex;
      max-width: 1280px;
      width: 100%;
      justify-content: flex-start;
      padding: 1em;
    }
  }
  @media only screen and (min-width: 992px) {
    .footer {
      padding: 3em 0;
      grid-area: footer;
    }
    .footer__menu {
      display: flex;
      max-width: 1280px;
      width: 100%;
      margin: auto;
      border-bottom: solid thin #ccc;
      padding: 1em 0 2em;
    }
    .footer__info,
    .footer__social {
      display: flex;
      max-width: 1280px;
      width: 100%;
      justify-content: center;
      padding: 2em 0;
    }
    .footer__menu-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: center;
      padding: 0.5em;
      width: 25%;
      gap: 16px;
    }
    .footer__menu-box h6 {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
    .footer__terms {
      display: flex;
      max-width: 1280px;
      width: 100%;
      justify-content: center;
      padding: 1em 0 0;
    }
    .footer__terms :global(p) {
      font-size: 12px;
    }
  }
`;
