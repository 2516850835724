import Icon from 'components/utils/Icon';
import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import { InlineIcon } from '@iconify/react';
import style from './footer.style';
import BannerInfo from './banner-info';
import React from 'react';

function Footer({ referralCode }) {
  const { isMobile } = useResponsive();
  const { TRANSLATIONS } = useTranslation();

  return (
    <>
      <BannerInfo referralCode={referralCode} />
      <div className={`footer`}>
        <div className={`footer__info ${isMobile && 'd-f fd-c g-20'}`}>
          <div
            className={`footer__menu-box ${
              isMobile ? 'w-100 ai-fs' : 'jc-c ai-c'
            }`}
          >
            <h6>{TRANSLATIONS.shop.securePayment}</h6>
            <div className={'payment d-f g-10 fw-w'}>
              <img
                src={'/images/footer/comodo-secure.png'}
                style={{ maxWidth: 90 }}
                alt={'comodo-secure'}
              />
            </div>
          </div>
          <div
            className={`footer__menu-box ${
              isMobile ? 'w-100 ai-fs' : 'jc-c ai-c'
            }`}
          >
            <h6>{TRANSLATIONS.footer.paymentMethod}</h6>
            <div className={'payment d-f g-10 fw-w'}>
              <img src={'/images/footer/visa-footer.png'} alt={'visa-footer'} />
              <img
                src={'/images/footer/paypal_footer.png'}
                alt={'paypal_footer'}
              />
              <img src={'/images/footer/mc_symbol.svg'} alt={'mc_symbol'} />
            </div>
          </div>
          <div
            className={`footer__menu-box ${
              isMobile ? 'w-100 ai-fs' : 'jc-c ai-c'
            }`}
          >
            <h6>{TRANSLATIONS.footer.shipping}</h6>
            <div className={'payment d-f g-10 fw-w'}>
              <img
                src={'/images/footer/fedex_footer.png'}
                alt={'fedex_footer'}
              />
              <img src={'/images/footer/dhl_footer.png'} alt={'dhl_footer'} />
              <img src={'/images/footer/brt.svg'} alt={'brt'} />
            </div>
          </div>
        </div>
        <div className={`footer__social`}>
          <a
            href="https://www.facebook.com/bdroppy.official"
            target="_blank"
            rel="noreferrer"
          >
            <Icon type="facebook" style={{ fontSize: 18 }} />
          </a>
          <a
            href="https://www.instagram.com/bdroppy/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon type="instagram" style={{ fontSize: 18 }} />
          </a>
          <a
            href="https://www.youtube.com/c/BDroppy"
            target="_blank"
            rel="noreferrer"
          >
            <Icon type="youtube" style={{ fontSize: 18 }} />
          </a>
          <a
            href="https://www.tiktok.com/@bdroppy"
            target="_blank"
            rel="noreferrer"
          >
            <InlineIcon icon="ic:baseline-tiktok" style={{ fontSize: 24 }} />
          </a>
        </div>
        <div className={`footer__terms ${isMobile && 'd-f fd-c'}`}>
          <p>{TRANSLATIONS.footer.bdroppy}</p>
          <a
            target={'_blank'}
            href="https://bdroppy.com/privacy-policy/"
            rel="noreferrer"
          >
            {TRANSLATIONS.sidebar.privacy}
          </a>
          <a
            target={'_blank'}
            href="https://bdroppy.com/terms-and-conditions/"
            rel="noreferrer"
          >
            {TRANSLATIONS.sidebar.terms}
          </a>
        </div>
        <style jsx>{style}</style>
      </div>
    </>
  );
}

export default Footer;
