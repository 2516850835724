import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import style from './index.style';
import Button from '../../../utils/Button';
import { useCallback } from 'react';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function BannerInfo({ referralCode }) {
  const { isMobile } = useResponsive();
  const { TRANSLATIONS, locale } = useTranslation();
  const router = useRouterWithLang();

  const goToCatalog = useCallback(() => {
    window.open(
      `/register?redirectUrl=${encodeURIComponent(
        `/product`
      )}&channelcode=affiliation_${referralCode}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, [referralCode]);

  return (
    <div className={`banner-info ta-c`}>
      <div className="relative d-f fd-c g-30 ai-c jc-c">
        <div className="d-f fd-c g-10 ai-c">
          <h3>{TRANSLATIONS.shop.footerPreText}</h3>
          <p>{TRANSLATIONS.shop.footerText}</p>
        </div>
        <div className="d-f jc-c">
          <Button
            className="large banner-btn"
            label={TRANSLATIONS.shop.cta}
            onClick={goToCatalog}
          />
        </div>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}

export default BannerInfo;
