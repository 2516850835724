import css from 'styled-jsx/css';

export default css`
  :global(body) {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  :global(html) {
    height: -webkit-fill-available;
  }
  .header__menu-wrap-center-logo {
    background-image: var(--logo-login);
    height: 50px;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 150px;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }
  .wrapper-social-ecommerce {
    background: var(--background-home-2);
    font-family: 'Poppins';
    padding-bottom: 4em;
  }
  .wrapper-social-ecommerce :global(input[type='checkbox']:checked) {
    background: #ff006c;
    -webkit-transition: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    transition: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    border-color: #ff006c;
  }
  .wrapper-social-ecommerce :global(input[type='checkbox']:not(.switch)) {
    --focus: 2px #ff006c;
    -webkit-appearance: none;
    height: 18px;
    outline: none;
    display: inline-block;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--grey-lighter);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 1;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  :global(.product-social h3) {
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    font-family: 'Poppins';
  }
  :global(.product-social) {
    margin: 10px 0px;
    padding: 1rem;
    max-width: 1200px;
    margin: auto;
  }
  .product-social--wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .product-social--info {
    padding: 4px;
  }
  .product-social--card {
    display: flex;
    width: 49%;
    margin-top: 5px;
    justify-content: space-between;
    background: var(--background-utils);
    transition: 0.3s;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .product-social--card:hover {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
      0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .product-social-img {
    height: 180px;
  }
  .product-social-img img {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: 0.5s;
  }
  .product-social-img img:hover {
    transform: scale(1.05);
  }
  .product-social--info__brand {
    font-size: 16px;
    font-weight: 600;
  }
  .product-social--info__subcategory {
    font-size: 14px;
    color: #09a5ec;
    font-weight: 400;
  }
  .product-social--info__price {
    font-size: 16px;
    font-weight: 500;
  }
  .product-social--btn {
    padding: 8px 16px;
    border-top: solid thin #ccc2;
    cursor: pointer;
    transition: 0.3s;
  }
  .product-social--btn:hover {
    color: #09a5ec;
  }
  .product-social--btn p {
    font-size: 15px;
    font-weight: 500;
  }
  .cashback {
    font-size: 12px;
    font-weight: 500;
    padding: 0.2em 0.1em;
    border-radius: 0px;
    background: #60f3d754;
    border: solid thin #60f3d7;
  }
  .cashback-amount {
    font-size: 13px;
    font-weight: 600;
  }
  .text-cashback {
    z-index: 2;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2em 0.1em;
    line-height: 15px;
    background: #60f3d754;
    border: solid thin #60f3d7;
  }
  .text-cashback > div {
    max-width: 1200px;
    margin: auto;
    padding: 0.5rem;
    text-align: center;
  }
  @media only screen and (min-width: 768px) {
    .product-social--card {
      display: flex;
      width: 31.3%;
    }
    .product-social--info {
      padding: 16px;
    }
    .product-social-img {
      max-height: 360px;
      height: 360px;
      overflow: hidden;
    }
    .product-social--btn {
      padding: 16px;
      border-top: solid thin #ccc2;
      cursor: pointer;
      transition: 0.3s;
    }
    .social-header h1 {
      font-size: 24px;
      font-family: 'Poppins';
      font-weight: 500;
    }
    :global(.product-social h3) {
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      font-family: 'Poppins';
    }
    .product-social--wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: flex-start;
    }
    .cashback {
      font-size: 15px;
      font-weight: 600;
      padding: 0.2em 0.4em;
      border-radius: 0px;
      background: #60f3d754;
      border: solid thin #60f3d7;
    }
    .cashback-amount {
      font-size: 16px;
      font-weight: 600;
    }
  }
  :global(.banner-btn) {
    background: var(--grey-dark);
    color: #fff;
  }
`;
