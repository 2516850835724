import css from 'styled-jsx/css';

export default css`
  .banner-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 3em 1em;
    position: relative;
    flex-direction: column;
    gap: 30px;
    background: linear-gradient(
        rgba(234, 230, 255, 0.27),
        rgba(255, 255, 255, 0)
      )
      no-repeat;
  }
  .banner-info:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    background-image: url('/images/home/new/bg_prefooter.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .banner-info h3 {
    font-size: 24px;
    font-family: 'Poppins';
    text-align: center;
  }
  .banner-info p {
    font-family: 'Poppins';
  }
  @media only screen and (min-width: 768px) {
    .banner-info h3 {
      font-family: 'Poppins';
      line-height: 30px;
      text-align: center;
    }
    .banner-info {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 5em 0;
      position: relative;
      flex-direction: column;
      gap: 30px;
      background: var(--background-utils);
    }
  }
`;
